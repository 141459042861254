.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #f8f9fa;
    border-bottom: 1px solid #ddd;
}

.header h1 {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
}

.nav-link {
    margin-left: 15px;
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
}

.nav-link:hover {
    text-decoration: underline;
}
