.home-page {
    text-align: center;
    margin-top: 50px;
}

.home-page h1 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 20px;
}

.home-page ul {
    list-style: none;
    padding: 0;
    margin: 20px auto;
    max-width: 600px;
}

.home-page ul li {
    font-size: 1.2rem;
    color: #555;
    margin: 5px 0;
}

.home-page img {
    display: block;
    margin: 20px auto;
    max-width: 90%;
    height: auto;
    border: 1px solid #ddd;
    border-radius: 5px;
}
