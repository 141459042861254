.register-page {
    text-align: center;
}

.register-page h1 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 20px;
}

.register-page form {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f9f9f9;
}

.register-page form div {
    margin-bottom: 15px;
    text-align: left;
}

.register-page form label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #555;
}

.register-page form input,
.register-page form select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1rem;
}

.register-page form button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
}

.register-page form button:hover {
    background-color: #0056b3;
}

.register-error {
    border: 1px solid red;
}

.error-message {
    color: red;
    font-size: 0.8rem;
    margin-top: 5px;
}
